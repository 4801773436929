import React from "react";
import theme from "theme";
import { Theme, Link, Image, Strong, Text, LinkBox, Box, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
import { AiOutlineMenu, AiFillFacebook } from "react-icons/ai";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index1"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"MMB"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03:42:52.439Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03:42:52.439Z"} type={"image/x-icon"} />
		</Helmet>
		<Section sm-padding="8px 0 8px 0" quarkly-title="Header-5" padding="0 0 0 0">
			<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
			<Box
				display="flex"
				padding="5px 0 5px 0"
				justify-content="space-between"
				align-items="flex-start"
				flex-direction="row"
				width="80%"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-width="50%"
				lg-width="70%"
				md-justify-content="flex-start"
			>
				<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
					<Image src="https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03:42:52.439Z" display="block" srcSet="https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
					<Text
						margin="0"
						md-margin="0px 0 0px 0"
						text-align="left"
						font="normal 500 28px/1.2 --fontFamily-sans"
						sm-margin="0px 0 0px 0"
						display="block"
						flex="0 1 auto"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Megan's Mobile Bartending
						</Strong>
					</Text>
				</LinkBox>
			</Box>
			<Components.QuarklycommunityKitMobileSidePanel
				menuPosition="full"
				breakpoint="lg"
				width="70%"
				sm-width="50%"
				md-width="50%"
				lg-width="30%"
			>
				<Override slot="Children" md-display="flex" />
				<Override
					slot="Content"
					padding="0px 0px 0px 0px"
					background="rgba(255, 255, 255, 0)"
					lg-background="#ffffff"
					lg-margin="0px 0px 0px 0px"
					flex="0 1 auto"
					justify-content="flex-end"
				/>
				<Override
					slot="Button Text"
					font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					text-transform="uppercase"
					letter-spacing="1px"
					sm-font="normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					sm-margin="0px 2px 0px 0px"
					lg-margin="0px 0px 0px 0px"
				/>
				<Override
					slot="Button Icon :closed"
					category="fi"
					icon={FiMenu}
					size="32px"
					padding="5px 7px 5px 7px"
					border-radius="50px"
				/>
				<Override
					slot="Button Icon"
					width="28px"
					height="28px"
					category="ai"
					icon={AiOutlineMenu}
					color="--dark"
					size="24px"
					lg-width="32px"
					lg-height="32px"
				/>
				<Override
					slot="Cross"
					lg-width="32px"
					lg-height="32px"
					size="32px"
					top="24px"
					right="24px"
				/>
				<Box
					align-items="center"
					lg-justify-content="center"
					lg-align-items="flex-start"
					justify-content="flex-end"
					display="flex"
					lg-flex-direction="column"
					lg-margin="0px auto 0px auto"
					lg-min-width="300px"
					lg-max-width="1280px"
					lg-width="90%"
					lg-padding="24px 0px 48px 0px"
				>
					<Box
						display="none"
						lg-width="100%"
						lg-margin="0px 0px 24px 0px"
						lg-display="flex"
						lg-padding="12px 0px 12px 0px"
					>
						<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
							<Image src="https://uploads.quarkly.io/6457b971d28fe100213a0f35/images/flower-logo-template.svg?v=2023-08-06T19:34:51.878Z" display="block" width="36px" height="36px" />
							<Text
								margin="0"
								md-margin="0px 0 0px 0"
								text-align="left"
								font="--lead"
								sm-margin="0px 0 0px 0"
								display="block"
								lg-font="--lead"
							>
								Company
							</Text>
						</LinkBox>
					</Box>
					<Components.QuarklycommunityKitMenu
						display="flex"
						filterMode="exclude"
						filterPages="/index"
						grid-gap="12px"
						lg-flex-direction="column"
						lg-padding="6px 0px 6px 0px"
						lg-margin="0px 0px 24px 0px"
						align-items="center"
						flex-wrap="wrap"
						overflow-x="visible"
						overflow-y="visible"
						lg-align-items="flex-start"
					>
						<Override
							slot="link"
							color="--darkL2"
							hover-color="--primary"
							font="--base"
							text-decoration-line="initial"
							transition="color 0.1s ease 0s"
							lg-font="--lead"
						/>
						<Override
							slot="item-active"
							border-width="0 0 2px 0"
							border-style="solid"
							border-color="--color-darkL2"
							lg-border-width="0 0 0 2px"
						/>
						<Override slot="item" padding="6px 6px 6px 6px" lg-padding="6px 12px 6px 12px" />
						<Override slot="link-active" cursor="default" color="--darkL2" hover-color="--darkL2" />
						<Override slot="link-about" />
						<Override slot="link-index1" href="/index">
							Home
						</Override>
					</Components.QuarklycommunityKitMenu>
					<Box
						width="25%"
						display="none"
						lg-width="100%"
						lg-flex-direction="column"
						lg-align-items="flex-start"
						lg-display="flex"
						justify-content="space-around"
						align-items="center"
						flex-wrap="wrap"
						lg-margin="32px 0px 0px 0px"
					>
						<SocialMedia
							instagram="https://instagram.com/instagram"
							margin="0px 0px 0px 0px"
							facebook="https://www.facebook.com/quarklyapp/"
							youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw"
							lg-display="flex"
							lg-grid-gap="12px"
						>
							<Override slot="link-twitter" margin="0px 0px 0px 5px">
								<div />
							</Override>
							<Override
								slot="link"
								border-radius="50%"
								background="transparent"
								hover-color="--light"
								display="flex"
								margin="0 5px 0 5px"
								padding="5x 5px 5px 5px"
								width="32px"
								height="32px"
								align-items="center"
								justify-content="center"
							/>
							<Override slot="icon" size="32px" border-radius="50px" color="--grey" />
							<Override slot="link-facebook" margin="0px 5px 0px 0px">
								<div />
							</Override>
						</SocialMedia>
					</Box>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section>
			<Components.Iframe />
		</Section>
		<Section
			padding="80px 0 80px 0"
			background="#eaeaea"
			align-items="center"
			justify-content="center"
			align-content="center"
			border-width="1px"
			border-style="solid"
			border-color="rgba(48, 48, 48, 0.51)"
			margin="px 0 0 0"
		>
			<Override
				slot="SectionContent"
				color="#ffffff"
				border-width="3px"
				border-color="#1a1a1a"
				border-style="solid"
				padding="24px 0px 24px 0px"
				box-shadow="5px 5px 40px 0 #6e6e6e"
				margin="0px 64px 0px 64px"
				background="#ffffff"
			/>
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--darkL2"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contact Me
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--darkL2" text-align="center">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						For any inquiries about our services or to discuss your upcoming event, please don't hesitate to get in touch. I am here to provide professional assistance and ensure your event is a success. Feel free to reach out through the contact form or use the contact details below. I am looking forward to serving you and making your event memorable!
					</Strong>
				</Text>
				<Link
					href="mailto:blank?hello@company.com"
					text-align="center"
					color="--darkL2"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					meganmobilebartending@gmail.com
				</Link>
				<Link
					href="tel:1234567899"
					text-align="center"
					color="--darkL2"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					(602) - 705 - 3102
				</Link>
			</Box>
			<LinkBox>
				<Icon category="ai" icon={AiFillFacebook} size="64px" color="rgba(0, 158, 255, 0.65)" />
			</LinkBox>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6588a38b8e2e8e0021750303"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});